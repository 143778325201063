<template>
	<div class="page">
    <img class="bj" src="../../assets/image/blgbanner.png" >
    <div >
      <router-link tag="div" to="/" class="logo">
        <img src="./assets/logo.png" >
      </router-link>
      <div class="form">
        <div style="color:#666;font-size:18px;margin-bottom:33px;line-height:18px;cursor: default;">—<span style="margin:0 10px;color:#333;">登录</span>—</div>
        <div class="form-item">
          <div class="form-input flex">
            <div slot="label">
              <img src="./assets/username.png" >
            </div>
            <input v-model="form.username" placeholder="请输入用户名称" />
          </div>
          <div class="form-error" v-if="false">请输入用户名称</div>
          <div v-else style="width:10px;height:10px;"></div>
        </div>
        <div class="form-item">
          <div class="form-input flex">
            <div slot="label">
              <img src="./assets/password.png" >
            </div>
            <input v-model="form.password" placeholder="请输入密码" />
          </div>
          <div class="form-error" v-if="false">请输入密码</div>
          <div v-else style="width:10px;height:10px;"></div>
        </div>
        <div class="subBtn">登录</div>
        <div>
          <span class="toLogin" @click="wxlogin">微信登录</span>
        </div>
        <router-link tag="span" to="/relation" class="toLogin">联系我们</router-link>
      </div>
      <div class="info">灵妙科技赋能企业数字化转型，⽤数据驱动发展</div>
    </div>
  </div>
</template>

<script>
let wx = null
export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  mounted() {},
  methods: {
    wxlogin() {
      wx = window.open('https://open.weixin.qq.com/connect/qrconnect?appid=wx202a5cd49a086587&scope=snsapi_login&redirect_uri=http%3A%2F%2Fwww.lingmiaotech.com%2F%23%2Ftransfers&state=&login_type=jssdk&self_redirect=true&styletype=&sizetype=&bgcolor=&rst=')
      window.getCode = function(e) {
        console.log('🚀 ~ file: index.vue ~ line 60 ~ wxlogin ~ e', e)
        wx.close()
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .page {
    height: 100vh;
    padding-top: 180px;
    box-sizing: border-box;
    .bj {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .logo {
      img {
        width: 323px;
      }
    }
    .form {
      width: 380px;
      padding: 30px 40px;
      margin: 50px auto 0;
      border-radius: 4px;
      background: #FFFFFF;
      font-size: 18px;
      color: #333333;
      .form-item {
        box-shadow: 0px 1px 0px 0px #D6E2EA;
        margin-bottom: 10px;
        &.error {
          box-shadow: 0px 1px 0px 0px #EC6E80;
          margin-bottom: 0;
        }
        .form-input {
          img {
            width: 20px;
            height: 44px;
            object-fit: scale-down;
            margin-left: 4px;
          }
          input {
            border: 0;
            font-size: 16px;
            width: 100%;
            margin-left: 35px;
            line-height: 44px;
            outline: none;
          }
        }
        .form-error {
          font-size: 12px;
          line-height: 30px;
          padding-left: 60px;
          text-align: left;
          color: #EC6E80;
        }
      }
      .subBtn {
        margin: 40px 0 20px;
        background: #3A9EFF;
        border-radius: 4px;
        line-height: 46px;
        color: #FFFFFF;
        font-size: 16px;
        box-shadow: 0px 10px 30px 0px rgba(58, 158, 255, 0.3);
      }
      .toLogin {
        font-size: 16px;
        color: #3A9EFF;
        line-height: 1;
        cursor: pointer;
      }
      .qrcode {
        width: 380px;
        height: 380px;
        box-shadow: 0 0 10px -1px #ccc;
        margin: 0 auto;
      }
    }
    .info {
      // padding: 80px 0 50px;
      // width: 100%;
      // height: 14px;
      // font-size: 14px;
      // font-family: Microsoft YaHei;
      // font-weight: 400;
      // color: #FFFFFF;
      // line-height: 32px;
      // opacity: 0.4;
      // position: fixed;
      // bottom: 60px;
      // padding: 50px 0 50px;
      width: 100%;
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      opacity: 0.4;
      padding-top: 50px;
      @media screen and (max-aspect-ratio: 2/1) {
        position: fixed;
        bottom: 60px;
      }
    }
  }
</style>
